import React from 'react';
import { Link } from 'react-router-dom';

const ProductFooter = () => {
  return (
    <footer style={{
      position: 'fixed', // Fixed position to stick to the bottom of the page
      width: '100%', // Full width
      height: '80px', // Reduced height
      backgroundColor: 'rgba(0, 0, 0, 0.7)', // Slightly translucent black background to blend in with the background
      padding: '20px',
      textAlign: 'center',
      borderTop: '1px solid grey', // Added top border
      zIndex: 10, // High z-index to ensure it's on top of other content
      bottom: 0, // Fixed to the bottom of the page
    }}>
      <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}>
        <Link to="/" style={{ textDecoration: 'none' }}>
          <img src="headshot.jpg" alt="PinkInference Logo" style={{ width: '50px', height: '50px' }} />
        </Link>
        <div>
          <Link to="/daq/" style={{ marginRight: '10px', textDecoration: 'none', color: '#727679', fontFamily: 'Fantasy' }}>Product</Link>
          <Link to="/mission/" style={{ marginRight: '10px', textDecoration: 'none', color: '#727679', fontFamily: 'Fantasy' }}>Mission</Link>
          <Link to="/education/" style={{ textDecoration: 'none', color: '#727679', fontFamily: 'Fantasy' }}>Education</Link>
        </div>
      </div>
    </footer>
  );
}
export default ProductFooter;