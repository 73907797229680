import * as React from 'react';

function Support() {
  return (
    <div>
      <h1>Support</h1>
      <p>This is the Support page</p>
    </div>
  );
}

export default Support;