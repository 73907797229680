import * as React from 'react';
import Testimonials from "./Testimonials.js";
import FooterComponent from './Footer.js';


function MainContent() {

  return (
    <div className="content">
      <div className="background page-one" style={{position: 'absolute', background: 'linear-gradient(to bottom, #FE5DA8, #983865)', top: 0, left: 0, width: '100%', height: '100vh', color: 'white', textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '0 20px' }}>
        <div style={{ maxWidth: '600px', fontFamily: 'Roboto Mono, monospace', margin: '0 auto', padding: '20px', borderRadius: '10px', textAlign: 'left', marginLeft: '100px', position: 'relative', top: '-75px' }}>
          <h1 style={{ fontSize: '50px', fontWeight: 'bold', fontStyle: 'italic' }}>The World’s Most Accessible MEA</h1>
          <p style={{ fontSize: '20px', fontWeight: 'bold', fontStyle: 'italic' }}>Pink Inference creates <span style={{ color: '#49D4C6' }}>microelectrode array</span> (MEA) technology to fuel in vitro innovation in neurology, cardiology, and wetware.</p>
        </div>
        <div style={{ position: 'absolute', top: '100px', right: '430px', width: '84px', height: '60vh', backgroundColor: '#49D4C6', transform: 'rotate(30deg)' }}></div>
        <div style={{ position: 'absolute', top: '80px', right: '328px', width: '84px', height: '84vh', backgroundColor: '#1B0F2B', transform: 'rotate(30deg)' }}></div>
        <div style={{ position: 'absolute', top: '220px', right: '220px', width: '84px', height: '60vh', backgroundColor: '#49D4C6', transform: 'rotate(30deg)' }}></div>
        <div style={{ position: 'absolute', top: '210px', right: '220px', width: '300px', height: '300px', backgroundImage: 'url("headshot.jpg")', backgroundSize: 'cover', zIndex: '1' }}></div>
      </div>

      {/* PAGE TWO */}
      <div className="background page-two" style={{position: 'absolute',  backgroundColor: '#1B0F2B', top: '100%', left: 0, width: '100%', height: '180%' }}>
        <div style={{ position: 'absolute', top: '160px', right: '140px', width: '545px', height: '245px', color: 'white', fontSize: '44px', fontFamily: 'Roboto Mono, monospace', textAlign: 'center', padding: '10px', zIndex: '2' }}>Unleashing Life’s Hidden <i>Symphonies</i></div>

        <div style={{ position: 'absolute', top: '120px', left: '300px', width: '345px', height: '345px', backgroundImage: 'url("pic1.jpg")', backgroundSize: 'cover', zIndex: '1' }}>
          <div>
            <p style={{ position: 'absolute', top: '340px', left: '30px', fontFamily: 'Roboto Mono, monospace', fontSize: '20px', fontWeight: 'bold', color: 'white' }}>60 - 256 Channel Options</p>
          </div>
        </div>
      
        <div style={{ position: 'absolute', top: '700px', left: '300px', width: '345px', height: '345px', backgroundImage: 'url("pic2.jpg")', backgroundSize: 'cover', zIndex: '1' }}>
          <div>
            <p style={{ position: 'absolute', top: '340px', left: '100px', fontFamily: 'Roboto Mono, monospace', fontSize: '20px', fontWeight: 'bold', color: 'white' }}>Small Size</p>
          </div>
        </div>

        <div style={{ position: 'absolute', top: '400px', right: '220px', width: '345px', height: '345px', backgroundImage: 'url("pic3.jpg")', backgroundSize: 'cover', zIndex: '1' }}>
          <div>
            <p style={{ position: 'absolute', top: '340px', left: '90px', fontFamily: 'Roboto Mono, monospace', fontSize: '20px', fontWeight: 'bold', color: 'white' }}>Incubator-Safe</p>
          </div>
        </div>

      </div>

      <div className="background page-three" style={{position: 'absolute', top: '280%', left: 0, width: '100%', height: '150%', backgroundImage: 'url("pic5.jpg")', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
      <div style={{ position: 'absolute', top: '3%', left: '0%', fontFamily: "'Syncopate', sans-serif", fontSize: '90px', textAlign: 'left', color: 'white', padding: '20px', maxWidth: '60%' }}>
        <p>Make ephys research accessible for every experimenter in the world</p>
      </div>
      <div style={{ position: 'absolute', top: '5%', left: '0%', fontFamily: "'Syncopate', sans-serif", fontSize: '20px', textAlign: 'left', color: 'white', padding: '20px', maxWidth: '60%' }}>
        <p>Our Mission</p>
      </div>
      </div>

      <div className="testimonial-page" style={{
        position: 'absolute', 
        top: '430%',
        left: 0,
        width: '100%',
        height: '50%', // Assuming each "page" takes up 100% of the viewport height
        backgroundColor: '#554B60', // Assign a background if needed, or use a backgroundImage similarly to above
        zIndex: 2 // Ensure it stacks above the background image if necessary
      }}>
      <div className="App">
        <header className="App-header" style={{ marginTop: "50px" }}>
          <h1> </h1>
          <Testimonials />
        </header>
      </div>
      </div>

      <div className="footer-component" style={{
  position: 'absolute',
  top: '480%', // Adjust the value accordingly based on the total height of the content above
  left: 0,
  width: '100%',
  height: 'auto',
  zIndex: 2 // Ensure it stacks properly with respect to other components
}}>
  <FooterComponent />
</div>

    </div>
  );
}

export default MainContent;
