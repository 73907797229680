import * as React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Logo from './Resources/PinkInferenceAndLogo.jpg'; // Import the logo image

const drawerWidth = 240;
const navItems = ['Products', 'Education', 'Mission', 'Support'];

function HoverMenu({ anchorEl, open, onClose, menuItems }) {
  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      MenuListProps={{ onMouseLeave: onClose }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      sx={{
        display: { xs: 'none', sm: 'block' },
      }}
    >
      {menuItems.map((submenuItem) => (
        <MenuItem key={submenuItem} onClick={onClose}>
          <Link to={`/${submenuItem}`} style={{ textDecoration: 'none', color: 'inherit' }}>{submenuItem}</Link>
        </MenuItem>
      ))}
    </Menu>
  );
}

const buttonStyle = {
  color: 'white', // Change button text color to white
  fontFamily: 'Roboto', // Material UI default font
  fontSize: '1rem', // or another font-size according to design
  textTransform: 'none',  // if you don't want uppercase text
  marginRight: '20px', // adds spacing between the nav items
};

function DrawerAppBar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  
  // Add a list of submenu items you want to show under the Products menu
  const productsSubMenu = ["DAQ", "Software", "Plates"];

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <img src={Logo} alt="Logo" style={{ height: '50px', width: 'auto', marginBottom: '10px' }} /> {/* Display the logo image */}
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item} disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }}>
              <ListItemText primary={item} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" sx={{ 
        top: '20px', 
        left: '50%', 
        transform: 'translateX(-50%)', 
        backgroundColor: 'rgba(255, 255, 255, 0.7)', // Adjust the alpha value to make it translucent
        height: '60px', 
        borderRadius: '30px', 
        padding: '0 20px', 
        width: '95%', 
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)', 
        transition: 'all 0.3s',
        backdropFilter: 'blur(10px)', // Adding blur effect
        border: '1px solid rgba(0, 0, 0, 0.2)', // Added slightly transparent border
        backgroundColor: 'rgba(255, 255, 255, 0)', // Remove the white tint, keep it translucent
      }}>
        <Toolbar sx={{ backgroundColor: 'rgba(255,255,255,0)' }}>
          <Link to="/" style={{ flexGrow: 1, display: { xs: 'none', sm: 'block' }, textDecoration: 'none' }}> {/* Link to the home screen */}
            <img src={Logo} alt="Logo" style={{ height: '40px', width: 'auto', marginRight: '10px' }} /> {/* Display the logo image */}
          </Link>
          <Box sx={{ display: { xs: 'none', sm: 'flex' }, alignItems: 'center' }}> {/* Changed to 'flex' */}
            {navItems.map((item) => (
              item !== 'Products' ? (
                <Button key={item} sx={buttonStyle}>
                  <Link to={`/${item}`} style={{ textDecoration: 'none', color: 'inherit' }}>{item}</Link>
                </Button>
              ) : (
                <div 
                  key={item} 
                  onMouseEnter={handleOpenMenu} 
                  onMouseLeave={handleCloseMenu}
                >
                  <Button sx={buttonStyle}>
                    {item}
                  </Button>
                  <HoverMenu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleCloseMenu}
                    menuItems={productsSubMenu}
                  />
                </div>
              )
            ))}
          </Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
      <Box component="main" sx={{ p: 3 }}>
        <Toolbar />
        <Typography>
        </Typography>
      </Box>
    </Box>
  );
}

DrawerAppBar.propTypes = {
  window: PropTypes.func,
};

export default DrawerAppBar;