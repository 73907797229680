import * as React from 'react';

function Education() {
  return (
    <div>
      <h1>Education</h1>
      <p>This is the Education page</p>
    </div>
  );
}

export default Education;