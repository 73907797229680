import React from 'react';
import Logo from './Resources/PinkInferenceAndLogo.jpg';

const FooterComponent = () => {
  return (
    <footer style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', backgroundColor: '#1c2331', padding: '0 2rem', marginBottom: '0' }}>
      <img src={Logo} alt="Logo" style={{ height: '50%', width: 'auto' }} />
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: '20%' }}>
        <a href="Terms" style={{ color: 'white', textDecoration: 'none' }}>Terms of Service</a>
        <a href="Privacy" style={{ color: 'white', textDecoration: 'none' }}>Privacy Policy</a>
        <a href="Service-Contract" style={{ color: 'white', textDecoration: 'none' }}>Service Contract</a>
      </div>
    </footer>
  );
};
export default FooterComponent;
