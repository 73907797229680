import ProductFooter from './ProductFooter'; 

function DAQ() {
  return (
    <div style={{position: 'absolute', background: 'black', top: 0, left: 0, width: '100%', height: '510%'}}>
      <div style={{position: 'absolute', width: '1380px', height: '121px', left: '50px', top: '232px', fontFamily: 'Sansation', fontStyle: 'normal', fontWeight: '700', fontSize: '87.1995px', lineHeight: '98px', textAlign: 'center', color: '#FFFFFF'}}>Introducing the MEAk</div>
      <div style={{position: 'absolute', width: '859.96px', height: '105px', left: '300px', top: '353px', fontFamily: 'Sansation Light', fontStyle: 'normal', fontWeight: '300', fontSize: '31.1423px', lineHeight: '35px', textAlign: 'center', color: '#FFFFFF'}}>
        Meet the most accessible MEA on the market. The MEAk has 60-512 electrode options, multiwall, and electrical stimulation options. All at 20% the price of competitors
      </div>
      <img src="meaphoto.jpg" alt="Descriptive text for the image" class="glow" style={{position: 'absolute', top: '300px', left: '47%', transform: 'translateX(-50%)', maxWidth: '80%', height: 'auto'}} />

      <div style={{position: 'absolute', top: '700px', left: '80px', width: '300px', height: '300px', overflow: 'auto', color: '#FFFFFF', textAlign: 'left', fontSize: '15px'}}>
        <div style={{fontSize: '24px', fontWeight: 'bold'}}>Small Footprint</div>
        The MEAk was designed to fit under microscopes, in incubators, and on small shelves. Its small footprint will allow you to perform your experiments while having little impendence on the rest of the lab!
      </div>

      <div style={{position: 'absolute', top: '1100px', left: '80px', width: '300px', height: '300px', overflow: 'auto', color: '#FFFFFF', textAlign: 'left', fontSize: '15px'}}>
        <div style={{fontSize: '24px', fontWeight: 'bold'}}>Small Footprint</div>
        The MEAk was designed to fit under microscopes, in incubators, and on small shelves. Its small footprint will allow you to perform your experiments while having little impendence on the rest of the lab!
      </div>

      <div style={{position: 'absolute', top: '700px', right: '80px', width: '300px', height: '300px', overflow: 'auto', color: '#FFFFFF', textAlign: 'left', fontSize: '15px'}}>
        <div style={{fontSize: '24px', fontWeight: 'bold'}}>Small Footprint</div>
        The MEAk was designed to fit under microscopes, in incubators, and on small shelves. Its small footprint will allow you to perform your experiments while having little impendence on the rest of the lab!
      </div>

      <div style={{position: 'absolute', top: '1100px', right: '80px', width: '300px', height: '300px', overflow: 'auto', color: '#FFFFFF', textAlign: 'left', fontSize: '15px'}}>
        <div style={{fontSize: '24px', fontWeight: 'bold'}}>Small Footprint</div>
        The MEAk was designed to fit under microscopes, in incubators, and on small shelves. Its small footprint will allow you to perform your experiments while having little impendence on the rest of the lab!
      </div>

      <div style={{position: 'absolute', top: '1925px', left: '50px', width: '500px', height: '300px', overflow: 'auto', color: '#FFFFFF', textAlign: 'left', fontSize: '15px'}}>
        <div style={{fontSize: '24px', fontWeight: 'bold'}}>Tired of old, broken software?</div>
      </div>

      <div style={{display: 'flex', justifyContent: 'space-between', padding: '0 50px', marginTop: '2000px'}}>
        <div style={{width: 'calc(50% - 10px)', backgroundColor: 'white', borderRadius: '15px', justifyContent: 'space-between', padding: '20px', boxSizing: 'border-box', height: '400px'}}>
          <div style={{fontSize: '24px', fontWeight: 'bold'}}>Guides You'll Love</div>
          <div style={{fontSize: '16px', padding: '20px'}}>
            <img src="whitecircle.jpg" alt="White Circle" style={{width: '215px', height: '215px', borderRadius: '50%', float: 'left', marginRight: '10px',  marginTop: '30px'}} />
          </div>
          <div style={{fontSize: '16px', padding: '20px', marginTop: '10px', marginLeft: '250px', fontWeight: 'bold'}}>
            Continuously updated as our team and community build new features, our docs help you get hacking.
            <a href="https://docs.pinkinference.com" style={{fontSize: '16px', fontWeight: 'bold', display: 'block', marginTop: '70px', color: 'black'}}>Pink Inference Docs</a>
          </div>
        </div>
        <div style={{width: 'calc(50% - 10px)', background: 'linear-gradient(to bottom, #DB1672, #FE5DA8)', justifyContent: 'space-between', borderRadius: '15px', padding: '20px', boxSizing: 'border-box', height: '400px'}}>
          <div style={{fontSize: '24px', fontWeight: 'bold', color: 'white'}}>Open-source Software</div>
          <div style={{fontSize: '16px', padding: '20px'}}>
            <img src="whitecircle.jpg" alt="White Circle" style={{width: '215px', height: '215px', borderRadius: '50%', float: 'left', marginRight: '10px', marginTop: '30px'}} />
          </div>
          <div style={{fontSize: '16px', padding: '20px', marginTop: '10px', marginLeft: '250px', fontWeight: 'bold', color: 'white'}}>
            Continuously updated as our team and community build new features, our docs help you get hacking.
            <a href="https://www.example.com" style={{fontSize: '16px', fontWeight: 'bold', display: 'block', marginTop: '70px', color: 'black'}}>Frame Docs</a>
          </div>
        </div>
      </div>

      <div style={{position: 'absolute', top: '2800px', left: '130px', width: '600px', height: '80px', color: '#FFFFFF', textAlign: 'left', fontSize: '15px'}}>
        <div style={{fontSize: '30px', fontWeight: 'bold'}}>Join our community of tinkerers, artists, and hackers, exploring the limits of what's possible in our Discord Group</div>
        <a href="https://www.example.com" style={{fontSize: '18px', fontWeight: 'bold', position: 'relative', top: '30px', left: '10px'}}>JOIN NOW</a>
      </div>

      <div style={{position: 'absolute', top: '2700px', right: '200px', width: '400px', height: '380px', color: '#FFFFFF', textAlign: 'left', fontSize: '15px', backgroundImage: 'url("discordphoto.jpg")', backgroundSize: 'contain'}}>
      </div>

      <div style={{position: 'absolute', top: '3200px', left: '50px', width: '600px', height: '80px', color: 'rgba(255, 255, 255, 0.5)', textAlign: 'left', fontSize: '15px'}}>
        <div style={{fontSize: '30px', fontWeight: 'bold', fontFamily: 'Fantasy'}}>Stay connected and informed about the latest from Pink Inference</div>
        <button style={{fontSize: '18px', fontWeight: 'bold', position: 'relative', top: '30px', left: '10px', color: 'rgba(255, 255, 255, 0.5)', backgroundColor: 'rgba(0, 0, 0, 0.5)', border: '2px solid rgba(255, 255, 255, 0.5)', borderRadius: '50px', padding: '10px'}}>Sign Up For Updates</button>
      </div>



    </div>
  );
}

export default DAQ;

