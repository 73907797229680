import * as React from 'react';

function Products() {
  return (
    <div>
      <h1>Products</h1>
      <p>This is the Products page</p>
    </div>
  );
}

export default Products;