import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import DrawerAppBar from './Components/DrawerAppBar';
import SimpleAppBar from './Components/ProductsPage/SimpleAppBar';
import FooterComponent from './Components/Footer';
import ProductFooter from './Components/ProductsPage/ProductFooter'; // Make sure this import path is correct
import Privacy from './Components/Legal/PrivacyPolicy';
import Terms from './Components/Legal/TermsofUse';
import Education from './Components/Education';
import Products from './Components/Products';
import Mission from './Components/Mission';
import Support from './Components/Support';
import MainContent from './Components/MainContent';
import DAQ from './Components/ProductsPage/DAQ';
import Software from './Components/ProductsPage/Software';
import Plates from './Components/ProductsPage/Plates';
import './App.css';

const AppWrapper = () => {
  const location = useLocation();
  const currentPath = location.pathname.toLowerCase();

  const showSimpleAppBarPaths = new Set(['/daq/', '/software/', '/plates/']);
  const shouldShowSimpleAppBar = showSimpleAppBarPaths.has(currentPath.endsWith('/') ? currentPath : `${currentPath}/`);

  const showProductFooterPaths = new Set(['/daq/', '/software/', '/plates/']);
  const shouldShowProductFooter = showProductFooterPaths.has(currentPath.endsWith('/') ? currentPath : `${currentPath}/`);

  return (
    <div className="app-container">
      {shouldShowSimpleAppBar ? <SimpleAppBar /> : <DrawerAppBar />}
      <div className="content">
        <Routes>
          <Route path="/" element={<MainContent />} />
          <Route path="/privacy/" element={<Privacy />} />
          <Route path="/terms/" element={<Terms />} />
          <Route path="/education/" element={<Education />} />
          <Route path="/products/" element={<Products />} />
          <Route path="/mission/" element={<Mission />} />
          <Route path="/support/" element={<Support />} />
          <Route path="/daq/" element={<DAQ />} />
          <Route path="/software/" element={<Software />} />
          <Route path="/plates/" element={<Plates />} />
          {/* Add other routes here if needed */}
        </Routes>
      </div>
      {shouldShowProductFooter ? <ProductFooter /> : <FooterComponent />}
    </div>
  );
};

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/*" element={<AppWrapper />} />
      </Routes>
    </Router>
  );
};

export default App;